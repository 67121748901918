import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'

import { ButtonWithLoading } from '~/components/button/with-loading'
import { Input } from '~/components/form-field/input'

import { HomeStudioPicker } from './home-studio-picker'
import type { PersonalValues } from './types'

import { Form, Heading, Title } from '../styled'

interface Props {
  readonly onSubmit: (values: PersonalValues) => void
  readonly initialValues?: PersonalValues
}

const phoneNumberPattern =
  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/u

const schema = yup
  .object({
    firstName: yup.string().trim().required().label('First name'),
    lastName: yup.string().trim().required().label('Last name'),
    phoneNumber: yup.string().matches(phoneNumberPattern, {
      excludeEmptyString: true,
      message: 'Please enter a valid phone number',
    }),
    birthDate: yup.string().required().label('Birth date'),
    homeLocationId: yup.string().required().label('Home studio'),
  })
  .required()

export const PersonalForm = ({ onSubmit, initialValues }: Props) => {
  const { register, formState, handleSubmit, control } = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Heading>Sign Up</Heading>
      <Title>Personal</Title>
      <Input
        label="First name"
        autoComplete="given-name"
        error={formState.errors.firstName?.message}
        {...register('firstName')}
      />
      <Input
        label="Last name"
        autoComplete="family-name"
        error={formState.errors.lastName?.message}
        {...register('lastName')}
      />
      <Input
        label="Phone number (optional)"
        autoComplete="tel"
        error={formState.errors.phoneNumber?.message}
        {...register('phoneNumber')}
      />
      <Input
        label="Birth date"
        type="date"
        autoComplete="bday"
        error={formState.errors.birthDate?.message}
        {...register('birthDate')}
      />
      <Controller
        control={control}
        name="homeLocationId"
        rules={{ required: true }}
        render={({ field }) => {
          return <HomeStudioPicker {...field} />
        }}
      />
      <ButtonWithLoading
        inverted
        block
        isLoading={false}
        disabled={!formState.isValid}
      >
        Continue
      </ButtonWithLoading>
    </Form>
  )
}
